import React, { Component } from "react";
import { withStyles } from "material-ui/styles";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import compose from "recompose/compose";
import Paper from "material-ui/Paper";
import { Checkbox } from "../../components/common";
import Table from "../../components/Table";
import classNames from "classnames";
import { Link } from "react-router";
import FeedbackPopover from "../../components/FeedbackPopover";
import _ from "lodash";
import { titleFor } from "../../utils/strings";
import { trackMetric as trackMetricAction } from "../../actions/plataformActions";
import { crudGetOne as crudGetOneAction } from "../../actions/dataActions";

import api from "../../services/api";

import axios from "axios";

import { useSelector } from "react-redux";

import { StartBtn } from "./styles";

const columnData = [
  { id: "item", numeric: false, disablePadding: true, label: "Item do Edital" },
  {
    id: "relevance",
    numeric: false,
    disablePadding: true,
    label: "Tópico Quente?",
    center: true,
  },
  {
    id: "teoria",
    numeric: false,
    disablePadding: false,
    label: "Teoria",
    center: true,
  },
  {
    id: "revisao",
    numeric: false,
    disablePadding: false,
    label: "Revisão",
    center: true,
  },
  {
    id: "questoes",
    numeric: false,
    disablePadding: false,
    label: "% de acertos em questões",
    center: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    center: true,
  },
];

const styles = (theme) => ({
  wrapper: {
    flex: 1,
    background: theme.palette.background.primary,
    paddingTop: "64px",
    "@media (min-width: 960px) and (max-width: 1600px)": {
      marginLeft: "305px",
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
  },
  container: {
    boxShadow: theme.shadows[25],
    margin: "auto",
    marginTop: "16px",
    height: "fit-content",
    minHeight: "300px",
    maxWidth: "800px",
    background: "#fff", // Adicionado em carater experimental
    width: "100%!important", // Adicionado em carater experimental
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      marginTop: "48px",
    },
    marginBottom: "32px",
  },
  containerContent: {
    height: "fit-content",
    minHeight: "300px",
    maxWidth: "800px",
    background: "#fff", // Adicionado em carater experimental
    width: "100%!important", // Adicionado em carater experimental
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
    marginBottom: "32px",
  },
  row: {
    padding: "18px",
    [theme.breakpoints.up("sm")]: {
      padding: "40px 52px 32px 52px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  alignLeft: {
    marginLeft: "13px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...theme.typography.title,
    fontSize: "28px",
    lineHeight: "32px",
    color: "inherit",
  },
  percentBarContainer: {
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.clearGrey,
  },
  percentBar: {
    backgroundColor: theme.palette.orange,
    height: "2px",
  },
  percent: {
    display: "none",
    alignItems: "center",
    ...theme.typography.percent,
    paddingLeft: "16px",
  },
  week: {
    width: "100%",
    textTransform: "uppercase",
    ...theme.typography.info,
  },
  titleLine: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  section: {
    padding: "18px",
    [theme.breakpoints.up("sm")]: {
      padding: "18px 77px 24px 62px",
    },
    "&:not(:last-child)": {
      borderBottom: "rgba(0, 0, 0, 0.1)",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
    },
  },
  objetivoSection: {
    borderBottomWidth: "0px",
    backgroundColor: "rgba(200,200,200,0.3)",
    paddingBottom: "42px",
  },
  subSection: {
    paddingBottom: 10,
  },
  sectionTitle: {
    ...theme.typography.sectionTitle,
  },
  sectionSubtitle: {
    ...theme.typography.body3,
    lineHeight: "22px",
    fontSize: 15,
    marginLeft: 41,
    marginBottom: 10,
    color: theme.palette.black.a8,
  },
  objetivo: {
    color: theme.palette.black.a8,
    fontFamily: theme.typography.fontFamily,
    lineHeight: "22px",
  },
  checkbox: {
    marginRight: "10px",
    float: "left",
  },
  flexAdjust: {
    flex: 1,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  pontos: {
    height: 46,
    width: 54,
    borderRadius: 3,
    backgroundColor: "#F4F4F4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.black.a7,
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "22px",
  },
  link: {
    cursor: "pointer",
    minHeight: "4px",
    color: "#1953AB",
    fontSize: "17px",
    lineHeight: "24px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  hover: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  notLink: {
    color: theme.palette.text.basic,
    cursor: "text",
  },
  readingText: {
    marginBottom: "24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  comments: {
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily2,
    color: theme.palette.black.a8,
  },
  textLink: {
    color: theme.palette.blue,
  },
  textReadings: {
    color: theme.palette.text.primary,
    fontSize: 17,
    lineHeight: "24px",
  },
  exerciciosQtd: {
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily2,
    color: theme.palette.text.clear,
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: "13px",
    lineHeight: "18px",
  },
  divider: {
    padding: "0 5px",
  },
  questionTitle: {
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily2,
    color: theme.palette.text.clear,
    fontWeight: "bold",
  },
  text: {
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily2,
    color: theme.palette.text.basic,
  },
  forum: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "24px",
  },
  forumText: {
    paddingRight: "24px",
    [theme.breakpoints.up("sm")]: {
      paddingRight: "40px",
    },
  },
  topics: {
    padding: 0,
    paddingBottom: "32px",
  },
  editalTitle: {
    padding: "18px",
    paddingBottom: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "8px 56px 0px 62px",
    },
  },
  editalTopics: {
    padding: "0px 18px",
    paddingBottom: 0,
    marginTop: "-23px",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 56px 0px 62px",
    },
  },
  feedback: {
    marginBottom: "30px",
  },
  subSectionDesc: {
    fontFamily: theme.typography.fontFamily2,
    color: theme.palette.black.a6,
    fontSize: "15px",
    lineHeight: "22px",
  },
});

// const modalData = {type: 'biblioSubjectMaterial', id: 'forum'}

class BibliografiaContent extends Component {
  constructor(props) {
    super(props);

    const stateCheck = this.handleUserStatus(this.props);

    this.state = {
      ...stateCheck,
    };
  }

  handleUserStatus = ({ material = {} }) => {
    const {
      questions = [],
      extra = [],
      recommendedReadings = [],
      requiredReadings = [],
      userStatus = [],
    } = material;
    const stateCheck = {};

    if (Array.isArray(userStatus)) {
      userStatus.map(({ resourceId }) => (stateCheck[resourceId] = true));
    }

    questions.map(
      (item) => (stateCheck[item._id] = stateCheck[item._id] || false)
    );

    extra.map((item) => (stateCheck[item._id] = stateCheck[item._id] || false));

    recommendedReadings.map(
      (item) => (stateCheck[item._id] = stateCheck[item._id] || false)
    );

    requiredReadings.map(
      (item) => (stateCheck[item._id] = stateCheck[item._id] || false)
    );

    return stateCheck;
  };

  openForum = () => {
    const { user, modalData, setModalData, subscribeComments, _id } =
      this.props;
    setModalData({ ...this.props, userId: user._id });
    subscribeComments(modalData, _id);
  };

  handleClickItem = (idCheck, id, resourceName) => {
    const {
      materialCheckUserStatus,
      _id,
      material: { subject, week },
      concurso,
      trackMetric,
    } = this.props;
    document.getElementById(idCheck).click();
    materialCheckUserStatus(
      _id,
      id,
      subject._id,
      week,
      resourceName,
      !this.state[id],
      concurso.concursoId
    );
    trackMetric("Bibliografia - Ativação", {
      target: "resource",
      trigger: "check",
      parentResource: _id,
      resource: id,
      resourceName,
      filter: location.href.split("/").pop(),
    });
    this.setState({ ...this.state, [id]: !this.state[id] });
  };

  componentWillReceiveProps(nextProps) {
    const stateCheck = this.handleUserStatus(nextProps);
    this.setState({ ...this.state, ...stateCheck });
  }

  componentDidMount() {
    const { editalTopics } = this.props;

    if (editalTopics && editalTopics.length) {
      Array.from(
        new Set(editalTopics.map((editalTopic) => editalTopic.subject || ""))
      ).forEach((subject) => this.props.getPerformancePerEditalTopic(subject));
    }
  }

  renderEditalBlock() {
    const {
      classes,
      editalTopics = [],
      changeEditalTopics,
      questionPerformance,
    } = this.props;

    return editalTopics.length > 0 ? (
      <div className={classNames(classes.section, classes.topics)}>
        <h3 className={classNames(classes.sectionTitle, classes.editalTitle)}>
          Pontos abordados no edital
        </h3>
        {/* <EditalTopicsEvaluate items={editalTopics} _id={_id}/> */}
        <div className={classes.editalTopics}>
          <Table
            columnData={columnData}
            data={editalTopics}
            changeEditalTopics={changeEditalTopics}
            questionPerformance={questionPerformance}
          />
        </div>
      </div>
    ) : null;
  }

  track = (resourceId, resourceName) => {
    const { _id, trackMetric } = this.props;
    trackMetric("Bibliografia - Ativação", {
      target: "link",
      trigger: "click",
      parentResource: _id,
      resource: resourceId,
      resourceName,
      filter: location.href.split("/").pop(),
    });
  };

  handleLinkClick(event, item_id, urlLink) {
    event.preventDefault();
    this.track(item_id, "requiredReadings");
    try {
      var windowReference = window.open();
      api.defaults.headers.Authorization = localStorage.getItem("token");
      api.get(`/kderno-one-time-token`).then(({ data }) => {
        windowReference.location = `${urlLink}?onetime=${data.data.token}`;
      });
    } catch (e) {
      window.open(urlLink);
    }
  }

  licensePdf = (apostila) => {
    const token = localStorage.getItem("token");

    const { _id, trackMetric, cpf, phone_number } = this.props;

    if (!token) throw new Error("Token não encontrado");

    axios
      .post(
        "https://us-central1-clippingcacd.cloudfunctions.net/license-pdf-apostila/license-pdf-apostila",
        {
          // axios.post('http://localhost:8080/license-pdf-apostila/license-pdf-apostila', {
          filename: apostila.filename,
          document: cpf,
          phone_numer: phone_number,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.open(response.data);
        trackMetric("Apostilas - Download", {
          target: "link",
          trigger: "click",
          parentResource: _id,
          resource: apostila._id,
          resourceName: apostila.name,
          filter: location.href.split("/").pop(),
        });
      })
      .catch((error) => {
        console.log(error);
        alert(
          "Não foi possível realizar o download da apostila. Tente novamente mais tarde."
        );
      });
  };

  render() {
    const {
      classes,
      material = {},
      notebook = {},
      concurso,
      handleRoteiro,
      handleQuestoes,
      handleVideo,
      trackMetric,
    } = this.props;
    const {
      _id,
      goal,
      questions = [],
      extra = [],
      recommendedReadings = [],
      requiredReadings = [],
      videos = [],
      week,
      title,
      numOfRequiredMaterials = 0,
      numOfStudiedMaterials = 0,
      apostilas = [],
    } = material;

    const razaoPercent = parseInt(
      (numOfStudiedMaterials / (numOfRequiredMaterials || 1)) * 100,
      10
    );
    const percent = `${razaoPercent > 100 ? 100 : razaoPercent}%`;

    return (
      <div className={classes.wrapper}>
        <Paper className={classes.container}>
          <div className={classes.row}>
            <div className={classes.week}>
              {titleFor("ciclo", concurso)} {week}
            </div>
            <div className={classes.titleLine}>
              <div className={classes.title}>{title}</div>
              <div className={classes.percent}>{percent}</div>
            </div>
          </div>
          <div className={classes.percentBarContainer}>
            <div className={classes.percentBar} style={{ width: percent }} />
          </div>

          <div className={classes.containerContent}>
            <div
              className={classNames(classes.section, classes.objetivoSection)}
            >
              <h3 className={classes.sectionTitle}>Objetivo</h3>
              {!_.isEmpty(material) && (
                <div
                  className={classes.objetivo}
                  dangerouslySetInnerHTML={{ __html: goal }}
                ></div>
              )}
            </div>

            {["PRF", "TRIBUNAIS", "TRT"].includes(concurso.name)
              ? this.renderEditalBlock()
              : null}

            {/* {(this.props.userRole === '5a29412efcf99317fb323d6e' || localStorage.scope.includes('PLUS')) && apostilas.length > 0 ?  */}
            <div
              className={classes.section}
              style={{ backgroundColor: "#fff5ec" }}
            >
              <h3 className={classes.sectionTitle}>
                Apostila e materiais exclusivos Clipping
                <span style={{ color: "#fc5836", fontWeight: "bold" }}>+</span>
              </h3>
              {apostilas.map((apostila) => (
                <div key={apostila._id} className={classes.column}>
                  <div className={classes.readingText}>
                    <div className={classes.checkbox}>
                      <Checkbox
                        id={`c${apostila._id}${_id}`}
                        label={{
                          onClick: () =>
                            this.handleClickItem(
                              `c${apostila._id}${_id}`,
                              apostila._id,
                              "apostilas"
                            ),
                        }}
                        checked={this.state[apostila._id]}
                      />
                    </div>
                    <div
                      className={classNames(
                        classes.column,
                        classes.flexAdjust,
                        classes.alignLeft
                      )}
                    >
                      {apostila.name}
                    </div>
                    <div
                      className={classNames(classes.column, classes.alignRight)}
                    >
                      <StartBtn onClick={() => this.licensePdf(apostila)}>
                        Download
                      </StartBtn>
                    </div>
                  </div>
                  <p className={classes.subSectionDesc}>
                    * Encontrou erro? Reporte ao final desta página.
                    <br />
                    ** O download demora alguns segundos para iniciar, por conta
                    do licenciamento do PDF.
                  </p>
                </div>
              ))}
            </div>
            {/* : null} */}

            {requiredReadings.length > 0 || recommendedReadings.length > 0 ? (
              <div className={classes.section}>
                <h3 className={classes.sectionTitle}>Leituras Recomendadas</h3>
                {requiredReadings.length > 0 ? (
                  <div className={classes.subSection}>
                    <h4 className={classes.sectionSubtitle}>
                      Leituras Obrigatórias
                    </h4>
                    {requiredReadings.map((props) => {
                      const {
                        _id: item_id,
                        name,
                        guide,
                        text = "",
                        url = "",
                      } = props;
                      const objId = `c${item_id}${_id}`;
                      let urlLink = undefined;
                      if (url) {
                        urlLink = url === "" ? undefined : url.trim();
                      }
                      return (
                        <div key={item_id} className={classes.column}>
                          <div className={classes.readingText}>
                            <div className={classes.checkbox}>
                              <Checkbox
                                id={objId}
                                label={{
                                  onClick: () =>
                                    this.handleClickItem(
                                      objId,
                                      item_id,
                                      "requiredReadings"
                                    ),
                                }}
                                checked={this.state[item_id]}
                              />
                            </div>
                            <div
                              className={classNames(
                                classes.column,
                                classes.alignLeft
                              )}
                            >
                              <a
                                className={
                                  urlLink ? classes.link : classes.textReadings
                                }
                                target="_blank"
                                href={urlLink}
                                onClick={(e) =>
                                  this.handleLinkClick(e, item_id, urlLink)
                                }
                              >
                                {name}
                              </a>
                              {guide && guide.length > 20 ? (
                                <div
                                  className={classes.link}
                                  onClick={() =>
                                    handleRoteiro({
                                      ...props,
                                      week,
                                      materia: title,
                                      resource: "biblioReadingsRequired",
                                    })
                                  }
                                >
                                  Acessar roteiro de leitura
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {text && text.length > 20 ? (
                            <div
                              style={{ marginLeft: "40px", marginTop: "-16px" }}
                            >
                              <div
                                className={classes.comments}
                                dangerouslySetInnerHTML={{ __html: text }}
                              />
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                {recommendedReadings.length > 0 ? (
                  <div className={classes.subSection}>
                    <h4 className={classes.sectionSubtitle}>
                      Leituras Complementares
                    </h4>
                    {recommendedReadings.map((props) => {
                      const {
                        _id: item_id,
                        name,
                        guide,
                        text = "",
                        url = "",
                      } = props;
                      const objId = `c${item_id}${_id}`;
                      let urlLink = undefined;
                      if (url) {
                        urlLink = url === "" ? undefined : url.trim();
                      }
                      return (
                        <div key={item_id} className={classes.column}>
                          <div className={classes.readingText}>
                            <div
                              className={classes.checkbox}
                              style={{ marginRight: "10px", float: "left" }}
                            >
                              <Checkbox
                                id={objId}
                                label={{
                                  onClick: () =>
                                    this.handleClickItem(
                                      objId,
                                      item_id,
                                      "recommendedReadings"
                                    ),
                                }}
                                checked={this.state[item_id]}
                              />
                            </div>
                            <div
                              className={classNames(
                                classes.column,
                                classes.alignLeft
                              )}
                            >
                              <a
                                className={
                                  urlLink ? classes.link : classes.textReadings
                                }
                                target="_blank"
                                href={urlLink}
                                onClick={() =>
                                  this.track(item_id, "recommendedReadings")
                                }
                              >
                                {name}
                              </a>
                              {guide && guide.length > 20 ? (
                                <div
                                  className={classes.link}
                                  onClick={() =>
                                    handleRoteiro({
                                      ...props,
                                      week,
                                      materia: title,
                                      resource: "biblioReadingsRecommendation",
                                    })
                                  }
                                >
                                  Acessar roteiro de leitura
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {text && text.length > 20 ? (
                            <div
                              style={{ marginLeft: "25px", marginTop: "-24px" }}
                            >
                              <div
                                className={classes.readingText}
                                dangerouslySetInnerHTML={{ __html: text }}
                              />
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            ) : null}

            {questions.length > 0 ? (
              <div className={classes.section}>
                <h3 className={classes.sectionTitle}>Exercícios</h3>
                <div className={classes.subSection}>
                  {questions.map(
                    ({ _id: item_id, name, test = {}, answer }) => {
                      const objId = `c${item_id}${_id}`;
                      return (
                        <div key={item_id} className={classes.readingText}>
                          <div className={classes.checkbox}>
                            <Checkbox
                              id={objId}
                              label={{
                                onClick: () =>
                                  this.handleClickItem(
                                    objId,
                                    item_id,
                                    "questions"
                                  ),
                              }}
                              checked={this.state[item_id]}
                            />
                          </div>

                          <div
                            className={classNames(
                              classes.column,
                              classes.flexAdjust,
                              classes.alignLeft
                            )}
                          >
                            <a
                              onClick={() =>
                                handleQuestoes({ _id: test._id, name })
                              }
                              className={classes.link}
                            >
                              {name}
                            </a>
                            <div className={classes.flexRow}>
                              <div className={classes.exerciciosQtd}>
                                {test.count.items} questões
                              </div>
                              {answer ? (
                                <div className={classes.flexRow}>
                                  <div
                                    className={classNames(
                                      classes.exerciciosQtd,
                                      classes.divider
                                    )}
                                  >
                                    |
                                  </div>
                                  <div
                                    onClick={() =>
                                      trackMetric("Bibliografia - Ativação", {
                                        target: "gabarito",
                                        trigger: "click",
                                        resource: test._id,
                                      })
                                    }
                                  >
                                    <Link
                                      className={classNames(
                                        classes.exerciciosQtd,
                                        classes.hover
                                      )}
                                      to={`/plano-de-estudos/${_id}/gabarito/${test._id}`}
                                    >
                                      Gabarito
                                    </Link>
                                    <span> | </span>
                                    <Link
                                      className={classNames(
                                        classes.exerciciosQtd,
                                        classes.hover
                                      )}
                                      to={`/simulados/${test._id}/relatorio`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Estatísticas
                                    </Link>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {answer ? (
                            <div className={classes.pontos}>
                              {answer.score} pts
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}

            {videos.length > 0 ? (
              <div className={classes.section}>
                <h3 className={classes.sectionTitle}>Vídeos</h3>
                <div className={classes.subSection}>
                  {videos.map(
                    ({
                      _id: item_id,
                      name,
                      url = "",
                      text = "",
                      youtube,
                      vimeo,
                    }) => {
                      const objId = `c${item_id}${_id}`;
                      let urlLink = undefined;
                      if (url) {
                        urlLink = url === "" ? undefined : url.trim();
                      }
                      return (
                        <div key={objId} className={classes.flexCenter}>
                          <div className={classes.checkbox}>
                            <Checkbox
                              id={objId}
                              label={{
                                onClick: () =>
                                  this.handleClickItem(
                                    objId,
                                    item_id,
                                    "videos"
                                  ),
                              }}
                              checked={this.state[item_id]}
                            />
                          </div>
                          <div
                            style={{ marginLeft: "13px" }}
                            onClick={() =>
                              handleVideo({ url: youtube, name, vimeo })
                            }
                          >
                            <a
                              className={classes.link}
                              target="_blank"
                              href={urlLink}
                            >
                              {name}
                            </a>
                          </div>
                          {text && text.length > 20 ? (
                            <div
                              style={{ marginLeft: "25px", marginTop: "-8px" }}
                            >
                              <div
                                className={classes.readingText}
                                dangerouslySetInnerHTML={{ __html: text }}
                              />
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}

            {extra.length > 0 ? (
              <div className={classes.section}>
                <h3 className={classes.sectionTitle}>Extras</h3>
                <div className={classes.subSection}>
                  {extra.map(({ _id: item_id, name, url = "", text = "" }) => {
                    const objId = `c${item_id}${_id}`;
                    let urlLink = undefined;
                    if (url) {
                      urlLink = url === "" ? undefined : url.trim();
                    }
                    return (
                      <div key={objId} className={classes.flexCenter}>
                        <div className={classes.checkbox}>
                          <Checkbox
                            id={objId}
                            label={{
                              onClick: () =>
                                this.handleClickItem(objId, item_id, "extra"),
                            }}
                            checked={this.state[item_id]}
                          />
                        </div>
                        <div style={{ marginLeft: "13px" }}>
                          <a
                            className={classNames(
                              classes.link,
                              !urlLink ? classes.notLink : false
                            )}
                            target="_blank"
                            href={urlLink}
                            onClick={() => this.track(item_id, "extra")}
                          >
                            {name}
                          </a>
                        </div>
                        {text && text.length > 20 ? (
                          <div
                            style={{ marginLeft: "25px", marginTop: "-8px" }}
                          >
                            <div
                              className={classes.readingText}
                              dangerouslySetInnerHTML={{ __html: text }}
                            />
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {!["TRT"].includes(concurso.name) && (
              <div className={classes.section}>
                <h3
                  className={classes.sectionTitle}
                  style={{ marginBottom: 0 }}
                >
                  Anotações
                </h3>
                <div className={classes.subSection}>
                  <div className={classes.subSectionDesc}>
                    Salve aqui seus fichamentos, links, videos, etc
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <div
                      onClick={() =>
                        trackMetric("Bibliografia - Ativação", {
                          target: "notebook",
                          trigger: "click",
                          resource: notebook._id,
                        })
                      }
                    >
                      <Link
                        className={classes.link}
                        to={`/plano-de-estudos/${_id}${
                          notebook ? "/caderno/" + notebook._id : ""
                        }`}
                      >
                        {notebook.title
                          ? notebook.title.replace("Semana", "Ciclo")
                          : notebook.title}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!["PRF", "TRIBUNAIS", "TRT"].includes(concurso.name)
              ? this.renderEditalBlock()
              : null}
          </div>
        </Paper>
        <FeedbackPopover customClass={classes.feedback} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    concurso: state.concurso.active,
    cpf:
      state.userData.data.cpf || state.user.profile.customer?.document_number,
    phone_number:
      state.user.profile.customer?.phone?.ddd +
      state.user.profile.customer?.phone?.number,
    userRole: state.user.profile.role,
    questionPerformance: state["editalTopic/userPerformance"]?.data?.undefined,
  };
};

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
  trackMetric: (name, data) => trackMetricAction(name, data),
  getPerformancePerEditalTopic: (_id) =>
    crudGetOneAction("editalTopic/userPerformance", `${_id}`, "", true, true),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(BibliografiaContent);
